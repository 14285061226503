@import "~@theme/variables.scss";
.accountSidebarLabelWithValue {
  margin-bottom: 16px;
}

.accountSidebarLabel {
  font-size: 12px;
  font-family: $akkuratMono;
  letter-spacing: 0.16em;
  color: $darkestGray;
  text-transform: uppercase;
}

.accountSidebarValue {
  font-size: 14px;
  display: flex;
}

.accountSidebarValueBtn {
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  color: $errorRed;
  margin-left: 10px;
}
