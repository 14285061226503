@import "~@theme/variables.scss";
.accountContentInner {
  position: relative;
  margin: 0 auto;
  padding: 0 15px;

  @media (min-width: $md) {
    max-width: 718px; // 688 + 30
  }

  @media (min-width: $lg) {
    max-width: 1140px; // 1110 + 30
    display: flex;
  }
}
