@import "~@theme/variables.scss";
.accountSidebarMember {
  display: none;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $mediumGray;

  @media screen and (min-width: $lg) {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.accountSidebarMemberInfo {
  flex-grow: 1;
  margin-left: 20px;
  @media screen and (min-width: $lg) {
    margin-left: 0;
  }
}
