@import "~@theme/variables.scss";
.accountSidebarNav {
  display: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: $mediumGray;
  margin-top: 16px;
  padding-top: 25px;

  @media screen and (min-width: $lg) {
    display: block;
  }
}
