@import "~@theme/variables.scss";
.accountSidebarMobileMenu {
  position: relative;
  margin-bottom: 24px;

  @media screen and (min-width: $lg) {
    display: none;
  }
}

.accountSidebarMobileMenuBtn {
  color: $slate;
  border-color: slate;
  font-size: 16px;
  line-height: 1.75;
  font-weight: 400;
  padding-left: 20px;
  text-align: left;

  :global {
    .arrow {
      margin-left: auto;
    }

    .link {
      display: flex;
      align-items: center;
      color: $slate;
      text-decoration: none;
      padding: 0;
      margin-bottom: 0;
      border-left: 0 none;
    }

    .icon {
      font-size: 21px;
      margin-right: 16px;
    }
  }
}

.accountSidebarMobileMenuPopper {
  position: absolute;
  margin-top: 2px !important;
  width: 100%;
  z-index: 100;
}

.accountSidebarMobileMenuPaper {
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
}

.accountSidebarMobileMenuList {
  padding: 10px 0;
  margin: 0;
  background-color: $white;
  border-width: 1px;
  border-style: solid;
  border-color: $slate;
  border-radius: 2px;

  :global {
    .link {
      display: flex;
      align-items: center;
      color: $darkGray;
      text-decoration: none;
      padding: 10px 20px;
      margin-bottom: 0;
      border-left: 0 none;

      &:hover {
        color: $slate;
        background-color: $lightGrayDark;
      }

      &.active {
        color: $slate;
      }
    }

    .icon {
      font-size: 21px;
      margin-right: 16px;
    }
  }
}
