@import "~@theme/variables.scss";
.accountProfileInfo {
  margin-bottom: 13px;
}

.accountProfileInfoLabel {
  font-size: 12px;
  letter-spacing: 0.16em;
  color: $darkestGray;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.accountProfileInfoValue {
  display: flex;
  align-items: center;

  :global {
    .icon {
      font-size: 12px;
      margin-left: 5px;
      color: $successGreen;
    }
  }
}

.accountProfileInfoBtn {
  margin-top: 16px;
  font-size: 14px;
}
