@import "~@theme/variables.scss";
.alertBlock {
  padding: 27px 20px 24px 73px;
  position: relative;
  margin-bottom: 24px;
  font-size: 14px;
  color: $darkGray;

  @media (min-width: #{$lg}) {
    display: flex;
    align-items: center;
    padding: 33px 48px 33px 82px;
  }

  :global {
    .icon {
      position: absolute;
      left: 20px;
      top: 34px;
      font-size: 32px;

      @media (min-width: #{$lg}) {
        left: 29px;
        top: 44px;
      }
    }
  }
}

.alertBlockTitle {
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  display: flex;
  color: $slate;

  a {
    color: inherit;
  }

  @media (min-width: $lg) {
    font-size: 24px;
  }
}

.alertBlockContent {
  flex-grow: 1;
}

.alertBlockDescription {
  font-size: 14px;
  line-height: 1.5715;
  color: $darkGray;
  margin-top: 10px;
}

.errorBlock {
  background-color: rgba($errorRed, 0.12);

  :global {
    .icon {
      color: $errorRed;
    }
  }
}

.errorBlockClose {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  color: $slate;
  font-size: 20px;
}

.errorBlockBtn {
  background-color: transparent;
  flex-shrink: 0;
  margin-top: 20px;

  &:hover {
    background-color: transparent;
  }

  @media (min-width: #{$lg}) {
    margin-top: 0;
    margin-left: 10px;
  }
}

.warningBlock {
  background-color: rgba($gold, 0.4);

  :global {
    .icon {
      color: $gold;
    }
  }
}

.successBlock {
  background-color: rgba($successGreen, 0.12);

  :global {
    .icon {
      color: $slate;
    }
  }
}

.thinMode {
  padding: 24px 48px 24px 20px;
  position: relative;
  margin-bottom: 0;
  font-size: 14px;
  color: $slate;
  justify-content: start;

  @media (min-width: #{$lg}) {
    justify-content: center;
    padding: 16px 48px 16px 20px;
  }

  .alertBlockTitle {
    line-height: normal;
  }

  .alertBlockContent {
    flex-grow: initial;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .alertBlockDescription {
    color: $slate;
    margin-top: 0;
    line-height: normal;
  }

  .errorBlockClose {
    padding: 16px;
    font-size: 17px;
  }

  a {
    border: 0;
    text-decoration: underline;
    padding-top: 0;
    padding-bottom: 0;
    display: inline-flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 16px;
    line-height: normal;

    @media (min-width: #{$lg}) {
      width: auto;
      display: block;
      margin: 0;
    }

    &:hover {
      border: 0;
    }
  }

  :global {
    .icon {
      position: static;
      font-size: 18px;
    }
  }
}
