@import "~@theme/variables.scss";
.accountFieldPseudo {
  height: 54px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: $mediumGray;
  padding-top: 15px;
  padding-bottom: 14px;
  cursor: default;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
