@import "~@theme/variables.scss";
.accountDialogContentStatus {
  :global {
    .icon {
      font-size: 46px;
    }

    .check {
      color: $successGreen;
    }

    .warn {
      color: $errorRed;
    }
  }
}

.accountDialogContentStatusTitle {
  margin-top: 36px;
  font-size: 56px;
  line-height: 1.08;
  font-weight: 300;
  color: $slate;
  letter-spacing: -0.04em;
}

.accountDialogContentStatusSubTitle {
  margin-top: 36px;
  font-size: 24px;
}

.accountDialogContentStatusActions {
  padding-top: 30px;

  button {
    width: 100%;
  }

  @media (min-width: #{$lg}) {
    padding-top: 50px;

    button {
      width: auto;
    }
  }
}
