@import "~@theme/variables.scss";
.SectionDivider {
  display: block;
  height: 1px;
  overflow: hidden;
  line-height: 1px;
  font-size: 1px;
  width: 100%;
  border: 0 none;
}

.colorDefault {
  background-color: $mediumGray;
}

.colorGray12 {
  background-color: rgba(0, 0, 0, 0.12);
}

.hideDesktop {
  display: block;
  @media (min-width: #{$lg}) {
    display: none;
  }
}

.Hidden {
  background-color: transparent;
}

.DesktopHidden {
  @media (min-width: #{$lg}) {
    background-color: transparent;
  }
}

.hideMobile {
  display: none;
  @media (min-width: #{$lg}) {
    display: block;
  }
}

.spacingLarge {
  margin: 48px 0;
}

.spacingDefault {
  margin: 34px 0;
}

.spacingSmall {
  margin: 20px 0;
  @media (min-width: #{$lg}) {
    margin: 25px 0;
  }
}

.spacingTiny {
  margin: 12px 0;
}

.spacingHideTop {
  margin-top: 0;
}

.spacingHideBottom {
  margin-bottom: 0;
}
