@import "~@theme/variables.scss";
.ProfileActions {
  text-align: center;
}

.ProfileActionsLeft {
  @media (min-width: #{$lg}) {
    text-align: left;
  }
}

.ProfileActionsRight {
  @media (min-width: #{$lg}) {
    text-align: right;
  }
}

.ProfileActionsCenter {
  @media (min-width: #{$lg}) {
    text-align: center;
  }
}

.ProfileActionsMobileFull {
  a,
  button {
    width: 100%;

    @media (min-width: #{$lg}) {
      width: auto;
    }
  }
}

.ProfileActionsBtnMinWidthMobile225 {
  button {
    min-width: 225px;

    @media (min-width: #{$lg}) {
      min-width: 0;
    }
  }
}

.ProfileActionsBtnMinWidth140 {
  a,
  button {
    min-width: 140px;
  }
}

.ProfileActionsBtnMinWidth221 {
  a,
  button {
    min-width: 221px;
  }
}

.ProfileActionsBtnMinWidth341 {
  a,
  button {
    min-width: 341px;
  }
}

.ProfileActionsBtnMinWidth380 {
  a,
  button {
    @media (min-width: #{$lg}) {
      min-width: 380px;
    }
  }
}

.ProfileActionsBtnMinWidth472 {
  a,
  button {
    @media (min-width: #{$lg}) {
      min-width: 472px;
    }
  }
}
