// Colors.
$black: #000;
$white: #fff;
$slate: #001e33;
$successGreen: #2ed47a;
$errorRed: #ff3c5f;
$gray: #323232;
$blue: #2e88bc;
$orange: #e14227;
$gold: #ffc43e;
$green: #c2edc4;
$lightText: rgba(50, 50, 50, 0.8); // 80% of #323232
$inActiveText: #c2c3c4;
$lightGray: #f4f6f7;
$border: rgba(0, 0, 0, 0.12); // 12% of #000
$darkGray: #677796;
$darkestGray: #546079;
$mediumGray: #b3bbca;
$mediumDarkGray: #6d6c6b;
$lightGrayDark: #f0f1f4;
$lightestGray: #f8f9fa;
$facebookBlue: #456baf;
$lightMint: #daeee7;
$mint: #b5dfd0;

// Fonts.
$akkurat: 'Akkurat', Helvetica, Arial, sans-serif;
$akkuratMono: 'Akkurat Mono', monospace;

// Breakpoints.
$xs: 0px;
$sm: 600px;
$md: 768px;
$lg: 1200px;
$xl: 1920px;
