@import "~@theme/variables.scss";
.accountContentWrapper {
  position: relative;
  padding: 24px 0;
  overflow-x: hidden;

  @media (min-width: $lg) {
    padding: 30px 0;
  }
}
