@import "~@theme/variables.scss";
.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $darkGray;
  padding: 2px 0 2px 10px;
  margin-bottom: 24px;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: transparent;

  &:hover {
    color: $slate;
  }
}

.active {
  border-left-color: $gold;
  color: $slate;
}

.icon {
  font-size: 16px;
  margin-right: 10px;
}
