@import "~@theme/variables.scss";
.InfoHelp {
  font-size: 14px;
  padding-top: 5px;
  color: $slate;
  margin-bottom: 20px;

  @media (min-width: #{$lg}) {
    margin-bottom: 36px;
  }

  a {
    color: $darkGray;
    font-size: inherit;
    padding: 0;
    min-width: inherit;
    font-weight: inherit;
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    vertical-align: inherit;
  }
}

.InfoHelpIcon {
  display: flex;
  font-size: 12px;
  line-height: 133%;
  color: $darkGray;

  svg {
    flex: 0 0 auto;
    width: 14px;
    height: 18px;
    margin-right: 10px;
  }
}
