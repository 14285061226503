@import "~@theme/variables.scss";
.accountProfileAvatarWrapper {
  display: flex;

  @media screen and (min-width: $lg) {
    display: block;
  }
}

.accountProfileAvatar {
  position: relative;
  width: 98px;
  margin-right: 20px;

  &:hover {
    .accountProfileAvatarImg {
      opacity: 0.25;
    }
  }

  @media screen and (min-width: $lg) {
    width: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.accountProfileAvatarIcon {
  font-size: 170px;
  width: 100%;
  height: auto;
  vertical-align: top;
  border-radius: 50%;
  background-color: $slate;
  color: $white;
}

.accountProfileAvatarImg {
  width: 100%;
  height: auto;
  vertical-align: top;
  border-radius: 50%;
  transition: all 250ms ease-out;
}

.accountProfileAvatarBtnEdit {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    background-color: transparent;
  }
}

.accountProfileAvatarBtns {
  align-self: center;

  button {
    display: block;

    @media screen and (min-width: $lg) {
      width: 100%;
    }
  }

  button + button {
    margin-top: 10px;
  }
}

