@import "~@theme/variables.scss";
.accountSidebarAvatarImg {
  display: block;
  width: 120px;
  border-radius: 50%;
  margin-bottom: 32px;
  flex-shrink: 0;
}

.accountSidebarAvatarImgSizeSmall {
  width: 40px;
  margin-bottom: 0;
}

.accountSidebarAvatarIcon {
  display: block;
  font-size: 120px;
  margin-bottom: 32px;
  flex-shrink: 0;
  color: $slate;
}

.accountSidebarAvatarIconSizeSmall {
  font-size: 40px;
  margin-bottom: 0;
}

.accountDialogEditPlanMemberIcon {
  width: 65px;
  font-size: 65px;
  margin-right: 13px;
  margin-bottom: 0;
  flex-shrink: 0;
}

.Initials {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mint;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  color: $slate;
  font-size: 20px;
  line-height: 1.3;
}

.Initials {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $mint;
  border-radius: 500px;
  text-transform: uppercase;
  color: $slate;
  font-size: 20px;
  line-height: 1.3;
}

.InitialsSmall {
  width: 40px;
  height: 40px;
}

.InitialsMedium {
  width: 65px;
  height: 65px;
  margin-right: 13px;
}
