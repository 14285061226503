@import "~@theme/variables.scss";
.accountSidebarWelcome {
  margin-bottom: 16px;

  :global {
    .icon {
      font-size: 16px;
    }
  }
}
